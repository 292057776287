import styled from "styled-components";
import { Link } from "gatsby";

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 50%;
    background: ${props => `linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ), url(${props.guitar_lessons ? './guitar_lessons.jpeg' : './music_production.jpeg'}) no-repeat`};
    background-position: left;
    background-size: cover;

  @media screen and (min-width: 840px) {
    width: 50%;
    height: 100%;
    background-position: center;
  }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.color.white};
    font-size: ${({ theme }) => theme.font.size.startPageTitle};
    text-align: center;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    font-weight: 700;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all .2s ease-in-out;
    
    &:hover {
        transform: scale(1.1);
    }
`;
