import React from "react";
import { Wrapper, Title, StyledLink } from './ChoosePageTile.styled';

function ChoosePageTile({ title, linkTo, guitarLessons }) {

    return (<Wrapper guitar_lessons={guitarLessons}>
        <StyledLink to={linkTo}>
            <Title>{title}</Title>
        </StyledLink>
    </Wrapper>)
}

export default ChoosePageTile;