import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    background-color: lightgray;
    height: 100vh;
    flex-direction: column;

    @media screen and (min-width: 840px) {
        flex-direction: row;
    }
`;

export const Title = styled.h2`
    color: blue;
`;

export const Divider = styled.div`
    height: 10px;
    width: 100%;
    color: ${({ theme }) => theme.color.white};
    @media screen and (min-width: 840px) {
        display: block;
        height: 100%;
        width: 2px;
    }
`;