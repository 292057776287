import React from "react";
import ChoosePageTile from '../ChoosePageTile/ChoosePageTile';
import { Wrapper, Divider } from './StartPage.styled';


export const StartPage = () => {
    return <Wrapper>
    <ChoosePageTile title={'Nauka gry na gitarze'} linkTo={'/lekcje-gry-na-gitarze'} guitarLessons/>
    <Divider />
    <ChoosePageTile title={'Produkcja muzyczna'} linkTo={'/produkcja-muzyczna'}/>
  </Wrapper>
}